import React from 'react';
import Helmet from 'react-helmet';
import HomeLayout from '../components/HomeLayout'
import Four0Four from '../components/Four0Four'

const ScheduleAppointment = () => (
  <HomeLayout>
    <Helmet title="Schedule an Appointment" />

    <Four0Four />
  </HomeLayout>
);

export default ScheduleAppointment